<template>
	<div>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="用户昵称">
					<el-input v-model="searchForm.search" placeholder="用户昵称" @submit.native.prevent></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="loadData(1)">查询</el-button>
					<el-button type="primary" @click="exportThis()">导出数据</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="username" label="用户姓名" width="200"></el-table-column>
			<el-table-column label="用户头像" width="200">
				<template slot-scope="scope">
					<img :src="scope.row.avatar" alt="">
				</template>
			</el-table-column>
			<el-table-column prop="nickname" label="微信昵称" width="200"></el-table-column>
			<el-table-column prop="phone" label="联系电话" width="200"></el-table-column>
			<el-table-column label="所在地址">
				<template slot-scope="scope">
					<div>{{scope.row.province}} {{scope.row.city}} {{scope.row.address}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="pay_money_sum" label="消费金额" width="100">
			</el-table-column>
			<el-table-column prop="buy_num_sum" label="购买件数" width="100"></el-table-column>
			<el-table-column prop="promotion_count" label="活动参加次数" width="200"></el-table-column>
			<el-table-column prop="check_count" label="核销次数"></el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: [],
				searchForm: {
					search: '',
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(id){
				if(id){
					this.$router.push(`/marketingCenter/group/edit/${id}`)
				}else{
					this.$router.push(`/marketingCenter/group/edit`)
				}
			},
			exportThis(){
				this.$api.marketing.exportUserList({
					search:this.searchForm.search
				}).then(res => {
					console.log(res)
					let a = document.createElement('a');
					//ArrayBuffer 转为 Blob
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					let objectUrl = URL.createObjectURL(blob);
					console.log(objectUrl)
					a.setAttribute("href", objectUrl);
					a.setAttribute("download", '会员列表.xls');
					a.click();
				})
			},
			loadData(num) {
				if (num == 1){
					this.searchForm.page = 1
				}

				this.loading = true;
				this.$api.marketing.getUserList(this.searchForm).then().then(res => {
					console.log(res)
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
	}
</style>
